
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  formatMilkScoreColour,
  formatFonterraMilkScoreColour
} from '@/utils/TableFormatters';
import { getImage } from '@/utils/SvgUtils';

@Component({
  methods: {
    formatMilkScoreColour,
    formatFonterraMilkScoreColour,
    getImage
  }
})
export default class MilkScoreIcon extends Vue {
  @Prop({ required: true, type: Boolean }) isFonterra!: boolean;
  @Prop({ required: true, type: Number }) milkScoreRating!: number;
  @Prop({ required: true, type: Number }) milkScoreOutOfSpec!: number;
  @Prop({ required: true, type: String }) tooltip!: string;
}
