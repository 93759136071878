var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"containerInner border-left border-right",style:(`min-width: ${_vm.tableWidth}px;`)},[_c('draggable',{staticClass:"mx-0 bg-grey mx-0 text-left px-2",staticStyle:{"font-weight":"bold","position":"sticky","top":"0","z-index":"100"},attrs:{"tag":"BRow"},on:{"change":_vm.columnOrderingChanged},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}},[_vm._l((_vm.columns),function(column){return _c('BCol',{key:column.name,class:`col${column.width} ${column.columnStyling} px-1 my-auto pointerOnHover`,attrs:{"id":column.name},on:{"click":function($event){column.sortable && _vm.sortInfo.sortBy ? _vm.updateSorting(column) : null}}},[_c('p',{class:`${column.headerTextStyling} my-2`},[_vm._v(" "+_vm._s(column.label())+" "),(
            column.sortable &&
              _vm.sortInfo.sortBy &&
              (!_vm.sortInfo.sortBy || _vm.sortInfo.sortBy.name != column.name)
          )?_c('BIcon',{staticClass:"ml-2 text-mediumGrey",attrs:{"icon":"chevron-expand"}}):_vm._e(),(
            column.sortable &&
              _vm.sortInfo.sortBy &&
              _vm.sortInfo.sortBy.name == column.name
          )?_c('BIcon',{class:`ml-2 text-${_vm.getAppColour(true)}`,attrs:{"icon":_vm.sortInfo.increasing ? 'sort-up-alt' : 'sort-down'}}):_vm._e()],1)])}),_c('EditTableColumnVisibility')],2),(_vm.products)?_c('div',{staticClass:"datatableContent overflow-y-auto"},[(_vm.products.length == 0 && !_vm.loading)?_c('div',[_c('p',[_vm._v("No products to show")])]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"mt-4 text-center",attrs:{"variant":_vm.getAppColour(true),"id":"spinner3"}})],1):_vm._e(),_vm._l((_vm.products),function(item){return _c('div',{key:`${item.productId}-${item.entityId}`,ref:"list",refInFor:true,attrs:{"border":""}},[_c('div',[_c('BRow',{staticClass:"datatableEntity mx-0 text-left border-bottom d-flex align-items-center align-self-center px-2",class:[
            _vm.getBackgroundColour(item),
            { 'text-mediumGrey': !_vm.isSupplying(item) }
          ],attrs:{"id":'datatableEntity_' + item.productId,"align-v":"center"},on:{"click":function($event){return _vm.selectRow(item, _vm.isParentHeader ? -1 : 0)}}},_vm._l((_vm.columns),function(column){return _c('BCol',{key:`${column.name}-${item.productId}`,class:`col${column.width} ${column.columnStyling} px-1 `,attrs:{"id":`${column.name}-${item.productId}`}},[(!column.parentComponent)?_vm._t(column.name,function(){return [_c('p',{staticClass:"my-2 textOverflowEllipsis",class:[
                  column.textStyling,
                  {
                    'text-mediumGrey':
                      column.parentFormattedValue(
                        item,
                        _vm.isParentHeader ? item : item.entities[0]
                      ) == '-'
                  }
                ],attrs:{"title":column.parentFormattedValue(
                    item,
                    _vm.isParentHeader ? item : item.entities[0]
                  )}},[_vm._v(" "+_vm._s(column.parentFormattedValue( item, _vm.isParentHeader ? item : item.entities[0] ))+" ")])]},{"item":item,"entity":_vm.isParentHeader ? item : item.entities[0]}):(
                column.parentFormattedValue(
                  item,
                  _vm.isParentHeader ? item : item.entities[0]
                ) !== '-'
              )?_c(column.parentComponent(
                  item,
                  _vm.isParentHeader ? item : item.entities[0],
                  _vm.active.split('/')[0],
                  _vm.isParentHeader ? -1 : 0
                ),_vm._b({tag:"component"},'component',{
                item: item,
                entity: _vm.isParentHeader ? item : item.entities[0],
                active: _vm.active.split('/')[0],
                index: _vm.isParentHeader ? -1 : 0,
                isParent: true
              },false)):_vm._e()],2)}),1),(
            !_vm.collapsible ||
              (_vm.collapsible && _vm.isParentHeader && item.entities.length > 1)
          )?_c('BCollapse',{attrs:{"accordion":`product-${item.productId}`,"role":"tabpanel","visible":!_vm.collapsible || _vm.isParentSelected(item.productId)}},[_vm._l((_vm.getNestedChildren(item)),function(entity,index){return _c('BRow',{key:index,staticClass:"datatableEntity mx-0 text-left border-bottom d-flex align-items-center align-self-center px-2",class:[
              item.productId + '/' + (_vm.isParentHeader ? index : index + 1) ===
                _vm.active && !_vm.isParentHeader
                ? 'bg-lightGrey'
                : 'bg-white',
              ,
              { 'text-mediumGrey': !_vm.isSupplying(entity) }
            ],attrs:{"id":'datatableEntity_' + item.productId,"align-v":"center"},on:{"click":function($event){return _vm.selectRow(item, _vm.isParentHeader ? index : index + 1)}}},_vm._l((_vm.columns),function(column){return _c('BCol',{key:`${column.name}-${entity.entityId}`,class:`col${column.width} ${column.columnStyling} px-1  `,attrs:{"id":`${column.name}-${entity.entityId}`}},[(!column.childComponent)?_vm._t(column.name,function(){return [_c('p',{class:[
                    column.textStyling,
                    'my-2 textOverflowEllipsis',
                    {
                      'text-mediumGrey':
                        column.childFormattedValue(item, entity) == '-'
                    }
                  ],attrs:{"title":column.childFormattedValue(item, entity)}},[_vm._v(" "+_vm._s(column.childFormattedValue(item, entity))+" ")])]},{"item":item,"entity":entity}):(column.childFormattedValue(item, entity) !== '-')?_c(column.childComponent(
                    item,
                    entity,
                    _vm.active,
                    _vm.isParentHeader ? index : index + 1
                  ),_vm._b({tag:"component"},'component',{
                  item: item,
                  entity: entity,
                  active: _vm.active,
                  index: _vm.isParentHeader ? index : index + 1,
                  isChild: true
                },false)):_vm._e()],2)}),1)}),(_vm.isParentHeader && _vm.collapsible)?_c('div',{staticClass:"spacerDropdown border-bottom bg-lightGrey"}):_vm._e()],2):_vm._e()],1)])})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }