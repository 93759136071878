
import AppName from '@/components/mixin/AppName.vue';
import { Product } from '@/store/models/Product';
import { getDate } from '@/utils/DateUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({
  methods: {
    getDate
  }
})
export default class StatusBadge extends mixins(AppName, ConstantsMixin) {
  @Prop() entity!: Product;

  get convertedStatus() {
    const statusConversion: {
      [key: string]: string;
    } = this.getStatusDisplayConversion();
    return statusConversion[this.entity?.status ?? 'live'];
  }
}
