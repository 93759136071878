
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ConfirmationModal extends Vue {
  @Prop() title!: string;
  @Prop() id!: string;
  @Prop({default: 'md'}) size!: string;

  public confirm() {
    this.$emit('confirm');
    this.closeModal();
  }

  public closeModal() {
    this.$root.$emit('bv::hide::modal', this.id, '#btnHide');
  }
}
