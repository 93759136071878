import ColumnOrderMoveEvent from '@/store/models/ColumnOrderMoveEvent';
import { getAppName } from './AppName';
import {
  getObjectItem,
  getSavedTableColumnOrder,
  removeUserItem,
  setObjectItem
} from './LocalStorageUtils';
import { getSpecificPageName } from './PageUtils';
import { AllowedField } from './types/AllowedFields';
import {
  ColumnIndex,
  CustomColumnIndex,
  CustomTableFieldIndex
} from './types/CustomColumnIndex';

export function adjustOrderingIndexes(
  move: ColumnOrderMoveEvent,
  customOrdering: CustomColumnIndex
): CustomColumnIndex {
  Object.entries(customOrdering).forEach(([key, index]) => {
    if (
      move.oldIndex < move.newIndex &&
      index > move.oldIndex &&
      index <= move.newIndex
    ) {
      customOrdering[key] = index - 1;
    } else if (
      move.oldIndex > move.newIndex &&
      index < move.oldIndex &&
      index >= move.newIndex
    ) {
      customOrdering[key] = index + 1;
    }
  });
  customOrdering[move.element] = move.newIndex;
  return customOrdering;
}

export function adjustAndSetColumnOrder(move: ColumnOrderMoveEvent) {
  const order =
    getObjectItem(
      `${getAppName(false)}${getSpecificPageName()}TableColumnOrder`
    ) ?? {};
  setObjectItem(
    `${getAppName(false)}${getSpecificPageName()}TableColumnOrder`,
    adjustOrderingIndexes(move, order)
  );
}

export function removeSavedColumnOrder() {
  removeUserItem(
    `${getAppName(false)}${getSpecificPageName()}TableColumnOrder`
  );
}

export function orderColumnsBySavedOrder(columns: any[]): any[] {
  const columnOrder = getSavedTableColumnOrder();

  const customOrderColumns: CustomTableFieldIndex = {}; //Column that have a custom defined index
  // Columns that have no custom ordering
  const defaultOrderColumns = columns.filter(column => {
    if (Object.keys(columnOrder).includes(column.name)) {
      customOrderColumns[column.name] = {
        column: column,
        index: columnOrder[column.name]
      };
      return false;
    }
    return true;
  });

  const customOrder = Object.values(customOrderColumns).sort(
    (a: ColumnIndex, b: ColumnIndex) => {
      return a.index - b.index;
    }
  );

  //Inserting the columns in at their custom index
  customOrder.forEach((columnIndex: ColumnIndex) => {
    defaultOrderColumns.splice(columnIndex.index, 0, columnIndex.column);
  });

  return defaultOrderColumns;
}

export function sortColumnsDefinedOrder(
  columns: any[],
  order: string[]
): any[] {
  return columns.sort((columnA: any, columnB: any) => {
    return order.indexOf(columnA.name) - order.indexOf(columnB.name);
  });
}

export function sortDefaultAlertsColumns(columns: any[]): any[] {
  // Just putting the action take column at the end
  return columns.sort((columnA, columnB) => {
    if (columnA.name == AllowedField.ACTION_TAKEN) return 1;
    if (columnB.name == AllowedField.ACTION_TAKEN) return -1;
    return 0;
  });
}
