var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{style:(`--colour: ${_vm.getAppColour(false)}`)},[_c('BBadge',{staticClass:"status-badge",class:_vm.entity && _vm.entity.status == _vm.ENTITY_STATUS_LIVE
        ? 'live-badge'
        : 'pending-badge',attrs:{"title":_vm.entity && _vm.entity.isNew
        ? `This ${_vm.getEntityName(
            false,
            _vm.entity
          )} recently went live on the ${_vm.getDate(_vm.entity.liveAt)}`
        : ''}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.convertedStatus))]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }