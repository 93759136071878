var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"my-auto text-center"},[_c('RepairIcon',{attrs:{"item":_vm.item,"classAlt":"mr-1 mb-1"}}),(_vm.isApp(_vm.PRODUCT_TYPE_FUEL) || _vm.isApp(_vm.PRODUCT_TYPE_FEED))?_c('span',[(_vm.entity.metrics.alert == 2)?_c('BIcon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle-fill","width":"15","height":"15","variant":"danger","alt":"Low Level","title":`This ${_vm.getEntityName(false, _vm.item)} is critically low on ${_vm.getAppName(
          false
        )}`}}):_vm._e(),(_vm.entity.metrics.alert == 1)?_c('img',{staticClass:"mr-1 mb-1",attrs:{"src":require("@/assets/icon_low_level2.svg"),"width":"15","height":"15","variant":"danger","alt":"Low Level","title":`This ${_vm.getEntityName(false, _vm.item)} is low on ${_vm.getAppName(false)}`}}):_vm._e()],1):_vm._e(),(
      _vm.isApp(_vm.PRODUCT_TYPE_FUEL) && _vm.outdatedVisible && _vm.entity.metrics.outdated
    )?_c('img',{staticClass:"mr-1 mb-1",attrs:{"src":require("@/assets/icon_timer.svg"),"width":"12","height":"12","alt":"Outdated","title":"The most recent reading for this tank was more than 9 hours ago"}}):_vm._e(),(_vm.isApp(_vm.PRODUCT_TYPE_MILK))?_c('span',[(_vm.isSupplying(_vm.entity))?_c('span',[(
          !_vm.isFonterra &&
            _vm.milkScore &&
            _vm.milkScore.rating &&
            _vm.milkScore.areaOutOfSpec
        )?_c('MilkScoreIcon',{attrs:{"isFonterra":false,"milkScoreRating":_vm.milkScore.rating,"milkScoreOutOfSpec":_vm.milkScore.areaOutOfSpec,"tooltip":'This value is the degrees out of spec times the minutes at that temperature'}}):_vm._e(),(_vm.isFonterra)?_c('MilkScoreIcon',{attrs:{"isFonterra":true,"milkScoreRating":_vm.fonterraMilkScore,"milkScoreOutOfSpec":_vm.fonterraMilkScore,"tooltip":`The milk score is at ${_vm.formatFonterraMilkScoreTooltip(
            _vm.fonterraMilkScore,
            !!_vm.fonterraMilkScore
          )} for Fonterra`}}):_vm._e()],1):_vm._e(),(_vm.isWarningMilkAge(_vm.entity))?_c('img',{staticClass:"ml-1",attrs:{"src":require("@/assets/milk_age_warning.svg"),"width":"16","height":"16","alt":"Age of milk","title":`The age of milk is above ${_vm.getAgeThresholdHrs(
          _vm.getMethod(_vm.entity.entityCustomSettingsData)
        )} hours`}}):_vm._e(),(!_vm.isSupplying(_vm.entity))?_c('img',{staticClass:"ml-1",attrs:{"src":require("@/assets/milk_not_supplying.svg"),"width":"16","height":"16","alt":"Age of milk","title":`This ${_vm.getEntityName(false)} is not ${
          _vm.entity.state == 'notUsed' ? 'in use' : 'supplying milk'
        }`}}):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }