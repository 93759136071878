import CustomField from '@/store/models/CustomField';
import {
  FuelMetricsTank,
  WaterMeterMetrics,
  WaterTankMetrics,
  MilkMetrics,
  Product
} from '@/store/models/Product';
import SortingInfo from '@/store/models/SortingInfo';
import dayjs from 'dayjs';
import cloneDeep from 'lodash.clonedeep';
import { milkTableColumnsFormatters } from './table-fields/MilkTableFields';
import { tableColumnFormatters } from './table-fields/TableFields';
import { AllowedField } from './types/AllowedFields';

export function sortWaterProducts(
  products: Product[],
  sortBy: string,
  increasing: boolean,
  isSortByCustom: boolean,
  isSortByEntityCustom: boolean,
  customFieldsDef: CustomField[],
  customEntityFieldsDef: CustomField[]
) {
  const productsClone = [...products];

  if (
    sortBy == 'currentUsage' ||
    sortBy == 'usageToday' ||
    sortBy == 'usageYesterday'
  ) {
    const sortedEntities = productsClone.map((prod: Product) => {
      if (prod?.entities ? prod?.entities?.length > 1 : false) {
        const aSortedEntities = prod?.entities?.sort(
          (c: Product, d: Product) => {
            if (c == undefined || d == undefined) return 0;
            const cVal = (c.metrics as WaterMeterMetrics)[sortBy];
            const dVal = (d.metrics as WaterMeterMetrics)[sortBy];
            return cVal > dVal ? (increasing ? 1 : -1) : increasing ? -1 : 1;
          }
        );
        return { ...prod, entities: aSortedEntities };
      } else {
        return prod;
      }
    });
    return sortedEntities.sort((a: Product, b: Product) => {
      const aEntities = a?.entities as Product[];
      const aMet = aEntities[0].metrics as WaterMeterMetrics;
      const aPercent = aMet[sortBy];
      const bEntities = b?.entities as Product[];
      const bMet = bEntities[0].metrics as WaterMeterMetrics;
      const bPercent = bMet[sortBy];

      return aPercent > bPercent ? (increasing ? 1 : -1) : increasing ? -1 : 1;
    });
  } else if (sortBy == 'percent') {
    const sortedEntities = productsClone.map((prod: Product) => {
      if (prod?.entities ? prod?.entities?.length > 1 : false) {
        const aSortedEntities = prod?.entities?.sort(
          (c: Product, d: Product) => {
            if (c == undefined || d == undefined) return 0;
            const cVal = (c.metrics as WaterTankMetrics)[sortBy];
            const dVal = (d.metrics as WaterTankMetrics)[sortBy];
            return cVal > dVal ? (increasing ? 1 : -1) : increasing ? -1 : 1;
          }
        );
        return { ...prod, entities: aSortedEntities };
      } else {
        return prod;
      }
    });
    return sortedEntities.sort((a: Product, b: Product) => {
      const aEntities = a?.entities as Product[];
      const aMet = aEntities[0].metrics as WaterTankMetrics;
      const aPercent = aMet[sortBy];
      const bEntities = b?.entities as Product[];
      const bMet = bEntities[0].metrics as WaterTankMetrics;
      const bPercent = bMet[sortBy];

      return aPercent > bPercent ? (increasing ? 1 : -1) : increasing ? -1 : 1;
    });
  } else if (sortBy == 'siteName') {
    const sortedEntities = productsClone.map(prod => {
      if (prod?.entities && prod?.entities?.length > 1) {
        const sortedProdEntities = prod?.entities?.sort(
          (a: Product, b: Product) => {
            if (a == undefined || b == undefined) return 0;
            return a.name > b.name
              ? increasing
                ? 1
                : -1
              : increasing
              ? -1
              : 1;
          }
        );
        return { ...prod, entities: sortedProdEntities };
      } else {
        return prod;
      }
    });
    return sortedEntities.sort((a, b) =>
      (a.siteName?.toLowerCase() ?? '') > (b.siteName?.toLowerCase() ?? '')
        ? increasing
          ? 1
          : -1
        : increasing
        ? -1
        : 1
    );
  } else if (isSortByCustom) {
    let sortType = 'Text Entry';
    customFieldsDef.forEach(def => {
      if (sortBy == def.name) {
        sortType = def.type;
      }
    });
    return productsClone.sort((a: Product, b: Product) => {
      const aCustomFieldData = a.customFieldData
        ? a.customFieldData[sortBy]
        : '';
      const bCustomFieldData = b.customFieldData
        ? b.customFieldData[sortBy]
        : '';
      if (!aCustomFieldData) {
        return 1;
      } else if (aCustomFieldData && !bCustomFieldData) {
        return -1;
      } else {
        return (sortType == 'Numeric entry'
          ? +aCustomFieldData
          : aCustomFieldData) >
          (sortType == 'Numeric entry' ? +bCustomFieldData : bCustomFieldData)
          ? increasing
            ? 1
            : -1
          : increasing
          ? -1
          : 1;
      }
    });
  } else if (isSortByEntityCustom) {
    let sortType = 'Text Entry';
    customEntityFieldsDef.forEach(def => {
      if (sortBy == def.name) {
        sortType = def.type;
      }
    });
    const invalidDigit =
      sortType == 'Numeric entry'
        ? increasing
          ? Infinity
          : 0
        : increasing
        ? 'zzzzzzzzzzzzzzzzzzz'
        : '';
    const sortedEntities = productsClone.map((prod: Product) => {
      if (prod?.entities ? prod?.entities?.length > 1 : false) {
        const aSortedEntities = prod?.entities?.sort(
          (c: Product, d: Product) => {
            if (c == undefined || d == undefined) return 0;
            const cVal = c.entityCustomFieldData
              ? c.entityCustomFieldData[sortBy]
                ? c.entityCustomFieldData[sortBy]
                : invalidDigit
              : invalidDigit;
            const dVal = d.entityCustomFieldData
              ? d.entityCustomFieldData[sortBy]
                ? d.entityCustomFieldData[sortBy]
                : invalidDigit
              : invalidDigit;
            return (sortType == 'Numeric entry' ? +cVal : cVal) >
              (sortType == 'Numeric entry' ? +dVal : dVal)
              ? increasing
                ? 1
                : -1
              : increasing
              ? -1
              : 1;
          }
        );
        return { ...prod, entities: aSortedEntities };
      } else {
        return prod;
      }
    });

    return sortedEntities.sort((a: Product, b: Product) => {
      const aEntities = a?.entities as Product[];
      const aVal = aEntities[0].entityCustomFieldData
        ? aEntities[0].entityCustomFieldData[sortBy]
          ? aEntities[0].entityCustomFieldData[sortBy]
          : invalidDigit
        : invalidDigit;
      const bEntities = b?.entities as Product[];
      const bVal = bEntities[0].entityCustomFieldData
        ? bEntities[0].entityCustomFieldData[sortBy]
          ? bEntities[0].entityCustomFieldData[sortBy]
          : invalidDigit
        : invalidDigit;

      return (sortType == 'Numeric entry' ? +aVal : aVal) >
        (sortType == 'Numeric entry' ? +bVal : bVal)
        ? increasing
          ? 1
          : -1
        : increasing
        ? -1
        : 1;
    });
  } else if (sortBy == 'partnerDefinedId') {
    const invalidDigit = increasing ? 'zzzzzzzzzzzzzz' : '';
    return productsClone.sort((a: Product, b: Product) => {
      const aVal = a.partnerDefinedId ? a.partnerDefinedId : invalidDigit;
      const bVal = b.partnerDefinedId ? b.partnerDefinedId : invalidDigit;

      return aVal > bVal ? (increasing ? 1 : -1) : increasing ? -1 : 1;
    });
  } else {
    const sortedEntities = productsClone.map(prod => {
      if (prod?.entities && prod?.entities?.length > 1) {
        const sortedProdEntities = prod?.entities?.sort(
          (a: Product, b: Product) => {
            if (a == undefined || b == undefined) return 0;
            return a.name > b.name
              ? increasing
                ? 1
                : -1
              : increasing
              ? -1
              : 1;
          }
        );
        return { ...prod, entities: sortedProdEntities };
      } else {
        return prod;
      }
    });
    return sortedEntities.sort((a, b) => {
      if (+a.productId > +b.productId) {
        return increasing ? 1 : -1;
      } else {
        return increasing ? -1 : 1;
      }
    });
  }
}

export function sortFuelAlerts(products: Product[]) {
  const productsClone = [...products];
  return productsClone.sort((a: Product, b: Product) => {
    if (a.entityComments != null && b.entityComments != null) {
      //Not quite working yet
      if (a.entityComments.timestamp > b.entityComments.timestamp) {
        return -1;
      } else {
        return 1;
      }
    } else if (a.entityComments == null && b.entityComments == null) {
      if (
        ((a.metrics as FuelMetricsTank).alertTs ?? 0) >
        ((b.metrics as FuelMetricsTank).alertTs ?? 0)
      ) {
        return -1;
      } else {
        return 1;
      }
    } else if (a.entityComments == null && b.entityComments != null) {
      return -1;
    } else if (a.entityComments != null && b.entityComments == null) {
      return 1;
    }

    return 0;
  });
}

export function sortMilkAlerts(products: Product[], sortBy: string) {
  const productsClone = [...products];
  return productsClone.sort((a: Product, b: Product) => {
    if (a.entityComments != null && b.entityComments != null) {
      if (a.entityComments.timestamp > b.entityComments.timestamp) {
        return -1;
      } else {
        return 1;
      }
    } else if (a.entityComments == null && b.entityComments == null) {
      if (
        sortBy == 'alert' &&
        (a.metrics as MilkMetrics).milkScore >
          (b.metrics as MilkMetrics).milkScore
      ) {
        return -1;
      } else if (
        (sortBy == 'alert' &&
          (a.metrics as MilkMetrics).milkScore ==
            (b.metrics as MilkMetrics).milkScore) ||
        sortBy == 'milkEnteredVat'
      ) {
        return dayjs().diff(
          dayjs((a.metrics as MilkMetrics).milkEnteredVat),
          'h'
        ) > dayjs().diff(dayjs((b.metrics as MilkMetrics).milkEnteredVat), 'h')
          ? -1
          : 1;
      } else {
        return 1;
      }
    } else if (a.entityComments == null && b.entityComments != null) {
      return -1;
    } else if (a.entityComments != null && b.entityComments == null) {
      return 1;
    }
    return 0;
  });
}

export function sortRequestedSites(requestedSites: Product[]) {
  return requestedSites.sort((a: Product, b: Product) => {
    const aVal = a.siteName ? a.siteName : '';
    const bVal = b.siteName ? b.siteName : '';
    if (aVal === bVal && a.entityId && b.entityId) {
      return a.entityId > b.entityId ? 1 : -1;
    }
    return aVal > bVal ? 1 : -1;
  });
}

export function nestedEntitiesSorter(
  products: Product[],
  sortInfo: SortingInfo
) {
  const productsClone = cloneDeep(products);
  const sortedEntities = productsClone.map((prod: Product) => {
    if (prod?.entities ? prod?.entities?.length > 1 : false) {
      const aSortedEntities = prod?.entities?.sort((c: any, d: any) => {
        if (c == undefined || d == undefined) return 0;
        const cVal = sortInfo.sortBy?.sortKey
          ? sortInfo.sortBy.sortKey(c, 'child')
          : undefined;
        const dVal = sortInfo.sortBy?.sortKey
          ? sortInfo.sortBy.sortKey(d, 'child')
          : undefined;
        if (dVal == undefined) {
          return -1;
        }
        if (cVal == undefined) {
          return 1;
        }
        return cVal > dVal
          ? sortInfo.increasing
            ? 1
            : -1
          : sortInfo.increasing
          ? -1
          : 1;
      });
      return { ...prod, entities: aSortedEntities };
    } else {
      return prod;
    }
  });
  return sortedEntities.sort((a: Product, b: Product) => {
    const aVal = sortInfo.sortBy?.sortKey
      ? sortInfo.sortBy.sortKey(a, 'parent')
      : undefined;
    const bVal = sortInfo.sortBy?.sortKey
      ? sortInfo.sortBy.sortKey(b, 'parent')
      : undefined;
    if (bVal == undefined) {
      return -1;
    }
    if (aVal == undefined) {
      return 1;
    }
    return aVal > bVal
      ? sortInfo.increasing
        ? 1
        : -1
      : sortInfo.increasing
      ? -1
      : 1;
  });
}

export function productsSorter(products: Product[], sortInfo: SortingInfo) {
  return products.sort((a: Product, b: Product) => {
    const aVal = sortInfo.sortBy?.sortKey
      ? sortInfo.sortBy.sortKey(a, 'parent')
      : undefined;
    const bVal = sortInfo.sortBy?.sortKey
      ? sortInfo.sortBy.sortKey(b, 'parent')
      : undefined;
    if (bVal == undefined) {
      return -1;
    }
    if (aVal == undefined) {
      return 1;
    }
    return aVal > bVal
      ? sortInfo.increasing
        ? 1
        : -1
      : sortInfo.increasing
      ? -1
      : 1;
  });
}

export function sortAlerts(products: Product[], sortInfo: SortingInfo) {
  return productsSorter(products, sortInfo);
}

export function sortByTimestamp(data: any[]) {
  return data.sort(
    (a: any, b: any) =>
      (a && a.timestamp ? a.timestamp : 0) -
      (b && b.timestamp ? b.timestamp : 0)
  );
}
