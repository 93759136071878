
import { storeState } from '@/store/Globals';
import { UpdateCustomColumnsVisibility } from '@/utils/CustomColumns';
import {
  getAppDefaultTableFields,
  getAppTableColumnWithCustomsWithFormatters
} from '@/utils/GenericUtils';
import { isPage } from '@/utils/PageUtils';
import { getPageTableFieldList } from '@/utils/table-fields/TableFieldLists';
import {
  SetVisibilityOfTableColumns,
  UpdateTableColumnsVisibility
} from '@/utils/TableColumnVisibility';
import cloneDeep from 'lodash.clonedeep';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConfirmationModal from '../modal/ConfirmationModal.vue';

@Component({
  components: { ConfirmationModal },
  methods: { isPage }
})
export default class EditTableColumnsVisibility extends mixins(AppName) {
  public tableColumnVisibility: {
    defaultColumns: any;
    siteCustoms: any;
    entityCustoms: any;
  } = {
    defaultColumns: [],
    siteCustoms: [],
    entityCustoms: []
  };

  get storeLoadingState() {
    return storeState.loading;
  }

  get hideCustomFields() {
    return isPage('sites');
  }

  mounted() {
    if (!this.storeLoadingState) {
      this.setTableColumnVisibility();
    }
  }

  @Watch('storeLoadingState')
  storeStateUpdated() {
    if (!this.storeLoadingState) {
      this.setTableColumnVisibility();
    }
  }

  public async setTableColumnVisibility() {
    let appFields;
    if (this.hideCustomFields) {
      appFields = getPageTableFieldList();
    } else {
      appFields = getAppDefaultTableFields(getPageTableFieldList());
    }

    const tableColumns = await getAppTableColumnWithCustomsWithFormatters(
      appFields
    );
    this.tableColumnVisibility.defaultColumns = SetVisibilityOfTableColumns(
      cloneDeep(tableColumns.defaultColumns)
    );
    this.tableColumnVisibility.siteCustoms = tableColumns.siteCustoms;
    this.tableColumnVisibility.entityCustoms = tableColumns.entityCustoms;
  }

  public submit() {
    UpdateTableColumnsVisibility(this.tableColumnVisibility.defaultColumns);
    UpdateCustomColumnsVisibility(
      Object.values(this.tableColumnVisibility.siteCustoms),
      'products'
    );
    UpdateCustomColumnsVisibility(
      Object.values(this.tableColumnVisibility.entityCustoms),
      this.getEntityApiWord()
    );
    this.$root.$emit('customFieldsUpdated');
  }

  public openEditColumnsModal() {
    this.$root.$emit(
      'bv::show::modal',
      'tableColumnVisibilityModal',
      '#btnShow'
    );
  }
}
