
import AppName from '@/components/mixin/AppName.vue';
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import ConstantsMixin from '../mixin/Constants.vue';

@Component
export default class NestedEntity extends mixins(AppName, ConstantsMixin) {
  @Prop() item!: Product;
  @Prop() entity!: any;
  @Prop() active!: string;
  @Prop() index!: number;

  public getEntityLinkImage(image: string) {
    if (this.isApp(this.PRODUCT_TYPE_MILK) && this.active !== '-1/-1') {
      const selected = this.active == `${this.item.productId}/${this.index}`;
      return require(`../../assets/${this.getAppName(false)}${image}${
        selected ? 'Selected' : 'Unselected'
      }.svg`);
    } else {
      return require(`../../assets/${this.getAppName(false)}${image}.svg`);
    }
  }

  public getEntityDisplayName(entityName: string) {
    return entityName.length < 25
      ? entityName
      : entityName.substr(0, 20) + '...';
  }
}
