var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.milkScoreRating || _vm.milkScoreOutOfSpec > 0)?_c('img',{staticClass:"ml-1",attrs:{"src":_vm.getImage(
      this.isFonterra
        ? `milk_${_vm.formatFonterraMilkScoreColour(
            _vm.milkScoreRating,
            !!_vm.milkScoreOutOfSpec
          )}_score`
        : `milk_${_vm.formatMilkScoreColour(
            _vm.milkScoreRating,
            !!_vm.milkScoreOutOfSpec
          )}_score`
    ),"width":"17","height":"17","alt":"Milk Score Warning","title":_vm.tooltip}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }