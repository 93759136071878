
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import ConstantsMixin from '../mixin/Constants.vue';

@Component
export default class RepairIcon extends mixins(ConstantsMixin) {
  @Prop() classAlt!: string;
  @Prop() item!: Product;

  get hasRepairFault() {
    return this.item.faultStatus == this.FAULT_STATUS_REPAIR;
  }
}
