
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import { formatLastMilkingDisplay } from '@/utils/formatters/DisplayValueFormatters';

@Component({})
export default class LastMilkingDisplay extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop({ default: null }) item!: Product | null;
  @Prop({ default: null }) entity!: Product | null;
  @Prop({ default: false }) isParent!: boolean;
  @Prop({ default: false }) isChild!: boolean;

  private get inProgress() {
    return this.formatLastMilkingDisplay() === 'In Progress';
  }

  private formatLastMilkingDisplay() {
    if (this.isParent && this.item == null) return '';
    if (this.isChild && this.entity == null) return '';

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return formatLastMilkingDisplay(this.isParent ? this.item! : this.entity!);
  }
}
