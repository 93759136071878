var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.actionProduct)?_c('BModal',{ref:`${_vm.actionProduct.productId}_${_vm.actionProduct.entityId}actionAlertModal`,staticClass:"d-flex",attrs:{"id":`${_vm.actionProduct.productId}_${_vm.actionProduct.entityId}actionAlertModal`,"title":"Schedule Note","centered":"","size":"md","header-bg-variant":"light","body-bg-variant":"light","footer-bg-variant":"light"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"modal-title w-100 pl-3",attrs:{"id":`${_vm.actionProduct.productId}_${_vm.actionProduct.entityId}actionAlertModal___BV_modal_title_`}},[_vm._v(" Schedule Note ")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeModal()}}},[_c('BIcon',{attrs:{"icon":"x","scale":"2"}})],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center mx-3"},[_c('BButton',{staticClass:"text-primary hover-white mr-2 px-5",attrs:{"variant":"outline-mediumGrey"},on:{"click":_vm.closeModal}},[_vm._v("Cancel")]),_c('BButton',{staticClass:"text-white ml-2 px-5",attrs:{"variant":"primary"},on:{"click":_vm.confirm}},[_vm._v("Confirm")])],1)]},proxy:true}],null,false,2034520713)},[_vm._t("default"),_c('div',{staticClass:"px-3",staticStyle:{"height":"300px","overflow-x":"auto"}},[(_vm.actionProduct)?_c('label',{staticClass:"mb-3 mt-2"},[_vm._v("Add a note to "),_c('strong',[_vm._v(_vm._s(_vm.actionProduct.siteName)+" | "+_vm._s(_vm.actionProduct.name))])]):_vm._e(),_c('FormTextInput',{attrs:{"inputAttrs":{
        id: 'schedulingNote',
        label: 'Note',
        labelClass: 'text-primary',
        formGroupClass: 'mb-0',
        type: 'text',
        variant: _vm.appColour,
        value: _vm.note
      },"id":"schedulingNote`"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }