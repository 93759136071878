
import { UpdateEntityComments } from '@/services/api/AlertsApi';
import Comment from '@/store/models/Comment';
import { FuelMetricsTank, Product } from '@/store/models/Product';
import User from '@/store/modules/User';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import FormTextInput from '../common/FormTextInput.vue';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    FormTextInput
  }
})
export default class EditCommentModal extends mixins(AppName) {
  @Prop() actionProduct!: Product;
  public note = '';
  public edited = false;

  mounted() {
    if (this.actionProduct) {
      this.note = this.actionProduct.entityComments?.comment ?? '';
    }
  }

  @Watch('actionProduct')
  productUpdated() {
    if (this.actionProduct) {
      this.note = this.actionProduct.entityComments?.comment ?? '';
      this.edited = false;
    }
  }

  public confirm() {
    const timestampNow = dayjs().unix();
    const data: Comment = {
      userId: User._currentUser?.id ?? 0,
      timestamp: timestampNow,
      comment: this.note,
      alertTs: (this.actionProduct?.metrics as FuelMetricsTank).alertTs ?? 0,
      volume: (this.actionProduct?.metrics as FuelMetricsTank).volume ?? 0,
      userName: User._currentUser?.name ?? ''
    };
    UpdateEntityComments({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      entityId: +(this.actionProduct.entityId ?? 0),
      entityType: this.getEntityApiWord(),
      data: data
    }).then(() => {
      this.$root.$emit('alertsUpdated');
    });
    this.closeModal();
  }

  public inputEdited() {
    this.edited = true;
  }

  public closeModal() {
    this.$root.$emit(
      'bv::hide::modal',
      `${this.actionProduct.productId}_${this.actionProduct.entityId}editCommentModal`,
      '#btnHide'
    );
  }
}
