
import AppName from '@/components/mixin/AppName.vue';
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import ConstantsMixin from '../mixin/Constants.vue';

@Component
export default class SiteNameField extends mixins(AppName, ConstantsMixin) {
  @Prop() item!: Product;
  @Prop() entity!: any;
  @Prop() active!: string;
  @Prop() index!: number;

  public getSiteDisplayName() {
    return this.item.siteName ? this.item.siteName : this.item.productId;
  }
  public getEntityDisplayName() {
    return this.entity.name
      ? this.entity.name
      : this.getEntityTitle(this.entity);
  }
}
