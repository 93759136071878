
import { getFormattedFeedAmount, isTonnes } from '@/utils/FeedUtils';
import { isLive } from '@/utils/formatters/DisplayValueFormatters';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component
export default class VolumeCapacityField extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() entity!: any;

  public getVolume() {
    if (isLive(this.entity)) {
      if (this.isApp(this.PRODUCT_TYPE_FEED)) {
        return getFormattedFeedAmount(
          this.entity.metrics.volume,
          this.entity,
          isTonnes(this.entity.capacity, this.entity)
        );
      } else {
        const volume = this.isApp(this.PRODUCT_TYPE_MILK)
          ? this.entity.metrics.vatVolume
          : this.entity.metrics.volume;
        return `${Math.round(volume).toLocaleString()}${this.getUnit(
          this.entity
        )}`;
      }
    } else {
      return '-';
    }
  }

  public getCapacity() {
    if (this.isApp(this.PRODUCT_TYPE_FUEL)) {
      return `${Math.round(
        this.entity.metrics.nominalVolume
      ).toLocaleString()}${this.getUnit(this.entity)}`;
    } else if (this.isApp(this.PRODUCT_TYPE_FEED)) {
      return getFormattedFeedAmount(this.entity.capacity ?? 0, this.entity);
    } else {
      return `${Math.round(
        this.entity.capacity
      ).toLocaleString()}${this.getUnit(this.entity)}`;
    }
  }
}
