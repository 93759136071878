import { GAEvent } from '@/services/ga/events/GAEvent';

export class EventAlertAddNote extends GAEvent {
  public eventName = 'alerts-add-note';
  public productId?: number;
  public entityId?: number;
  public entityType?: string;

  constructor(productId?: number, entityId?: number, entityType?: string) {
    super();
    this.productId = productId;
    this.entityId = entityId;
    this.entityType = entityType;
  }
}
