
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component({})
export default class Collapsible extends mixins(AppName, ConstantsMixin) {
  @Prop() item!: Product;
  @Prop() active!: string;

  private get icon(): string {
    return this.active == this.item.productId.toString()
      ? 'chevron-down'
      : 'chevron-right';
  }

  private get variant(): string {
    return this.active == this.item.productId.toString()
      ? 'secondary'
      : 'black';
  }
}
