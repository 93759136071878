
import { Product } from '@/store/models/Product';
import { SelectedRoute } from '@/utils/classes/SelectedRoute';
import { productType } from '@/utils/types/ProductType';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventModifyRoutePlan } from '@/services/ga/events/EventModifyRoutePlan';

@Component
export default class RouteCheckbox extends mixins(AppName, ConstantsMixin) {
  @Prop() item!: Product;
  @Prop() entity!: any;
  @Prop() active!: string;
  @Prop() index!: number;
  public route?: SelectedRoute;
  public checked = false;

  created() {
    setTimeout(() => this.createRoute(), 1);
    this.$root.$on('routeUpdated', () => {
      this.route?.updateChecked();
      this.updateChecked();
    });
  }

  mounted() {
    setTimeout(() => this.updateChecked(), 1);
  }

  @Watch('entity', { deep: true })
  entityUpdated() {
    this.updateChecked();
  }

  private async createRoute(): Promise<void> {
    return new Promise(resolve => {
      this.route = new SelectedRoute(this.getAppName(false) as productType);
      resolve();
    });
  }

  private updateChecked() {
    this.checked = this.isInRoute();
  }

  public isInRoute() {
    return (
      this.route?.isInRoute(
        'productChecked-' +
          this.item.productId +
          '-' +
          (this.index == -1 && this.isApp(this.PRODUCT_TYPE_MILK)
            ? '0000'
            : this.entity.entityId)
      ) ?? false
    );
  }

  public updateRoute() {
    let updatedAlert = '';
    if (this.index == -1) {
      GA.event<EventModifyRoutePlan>(
        this.$gtag,
        new EventModifyRoutePlan(
          this.isInRoute() ? 'remove' : 'add',
          this.item.productId,
          this.getEntityApiWord()
        )
      );
      if (this.isInRoute()) {
        updatedAlert =
          this.route?.removeAllEntitesFromChecked(
            this.item.productId.toString()
          ) ?? '';
      } else {
        updatedAlert =
          this.route?.addAllEntitesToChecked(this.item.productId.toString()) ??
          '';
      }
    } else {
      GA.event<EventModifyRoutePlan>(
        this.$gtag,
        new EventModifyRoutePlan(
          this.isInRoute() ? 'remove' : 'add',
          this.entity.entityId,
          this.getEntityApiWord()
        )
      );
      updatedAlert =
        this.route?.addToRoute(
          'productChecked-' + this.item.productId + '-' + this.entity.entityId
        ) ?? '';
    }

    this.$bvToast.toast(updatedAlert, {
      title: 'Route updated',
      toaster: 'b-toaster-bottom-center',
      solid: true,
      append: false,
      to: `/${this.getAppName(false)}-route-plan`
    });
    this.$root.$emit('routeUpdated');
  }
}
