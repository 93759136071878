import { FuelCustomSettingsData } from '@/store/models/CustomSettingsData';
import { Product } from '@/store/models/Product';
import CustomStore from '@/store/modules/CustomStore';
import User from '@/store/modules/User';

const usageDayPatternOptions = {
  5: { label: 'Monday to Friday', value: 5 },
  6: { label: 'Monday to Saturday', value: 6 },
  7: { label: 'Monday to Sunday', value: 7 }
};

export function getUsageDayPattern(product: Product | null) {
  return usageDayPatternOptions[
    (product?.entityCustomSettingsData as FuelCustomSettingsData)
      ?.usageDayPattern ?? 7
  ].label;
}

export function getThreshold(
  product: Product | null,
  type: 'lowLevelThreshold' | 'criticalLevelThreshold'
) {
  const customSettings = product?.entityCustomSettingsData as FuelCustomSettingsData;
  if (customSettings && customSettings[type]) {
    return customSettings[type];
  } else {
    const orgCustomSettings = CustomStore.customEntitySettings as FuelCustomSettingsData;
    if (orgCustomSettings) {
      return orgCustomSettings[type];
    }
    return 30;
  }
}

export function getLowLevelThreshold(product: Product | null) {
  return getThreshold(product, 'lowLevelThreshold');
}

export function getCriticalLevelThreshold(product: Product | null) {
  return getThreshold(product, 'criticalLevelThreshold');
}

export function goToSiteRequest() {
  const urls: { [id: number]: string } = {
    6656: 'https://www.levno.com/partner_transdieselrequest-0',
    9947: 'https://www.levno.com/partner_totaloilrequest',
    2094: 'https://www.levno.com/partner_farmlandsrequest',
    6836: 'https://www.levno.com/partner_mckeownrequest',
    2078: 'https://www.levno.com/partner_waitomorequest',
    2136: 'https://www.levno.com/partner_klrequest',
    2117: 'https://www.levno.com/partner_alliedrequest',
    2079: 'https://www.levno.com/partner_mcfallrequest',
    2084: 'https://www.levno.com/addsite/2084',
    3058: 'https://www.levno.com/partner_gofuelsrequest',
    2106: 'https://www.levno.com/addsite/2106'
  };
  const defaultUrl = 'https://www.levno.com/partner_requestfuel';
  if (urls[User.token.orgs[User._orgIndex].orgId]) {
    window.open(urls[User.token.orgs[User._orgIndex].orgId], '_blank');
  } else {
    window.open(defaultUrl, '_blank');
  }
}
