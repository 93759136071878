import { GAEvent } from '@/services/ga/events/GAEvent';
import { EntityApiType } from '@/utils/types/EntityApiType';

export class EventModifyRoutePlan extends GAEvent {
  public eventName = 'modify-route-plan';
  public action?: 'add' | 'remove';
  public entityId?: number;
  public entityType?: EntityApiType;

  constructor(
    action?: 'add' | 'remove',
    entityId?: number,
    entityType?: EntityApiType
  ) {
    super();
    this.action = action;
    this.entityId = entityId;
    this.entityType = entityType;
  }
}
