import HttpClient from '@/services/api/ApiBase';
import Comment from '@/store/models/Comment';

export const UpdateEntityComments = async (request: {
  partnerId: number;
  entityId: number;
  entityType: string;
  data: Comment;
}): Promise<any> => {
  const data = await HttpClient.post<any>(
    `/partner/${request.partnerId}/comments/${request.entityType}/${request.entityId}`,
    { data: request.data }
  );
  return data.data.data;
};
