
import { UpdateEntityComments } from '@/services/api/AlertsApi';
import { trackEvent } from '@/services/Mixpanel';
import Comment from '@/store/models/Comment';
import { FuelMetricsTank, Product } from '@/store/models/Product';
import User from '@/store/modules/User';
import { getAlertTimestamp } from '@/utils/AlertUtils';
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import FormTextInput from '../common/FormTextInput.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import { GA } from '@/services/ga/GoogleAnalytics';
import { EventSettingsEditDefault } from '@/services/ga/events/EventSettingsEditDefault';
import { EventAlertAddNote } from '@/services/ga/events/EventAlertAddNote';

@Component({
  components: {
    FormTextInput
  }
})
export default class ActionAlertModal extends mixins(AppName, ConstantsMixin) {
  @Prop() actionProduct!: Product;
  public note = '';
  public appColour = 'fuelColour';

  mounted() {
    this.appColour = this.getAppColour(true);
    this.$root.$on('bv::modal::show', (bvEvent: any, modalId: string) => {
      if (modalId == 'actionAlertModal') {
        this.note = '';
      }
    });
  }

  autoFillNote(note: string) {
    this.note = note;
  }

  public confirm() {
    const timestampNow = dayjs().unix();
    const data: Comment = {
      userId: User._currentUser?.id ?? 0,
      timestamp: timestampNow,
      comment: this.note,
      alertTs: getAlertTimestamp(this.actionProduct) ?? 0,
      volume: (this.actionProduct?.metrics as FuelMetricsTank).volume ?? 0,
      userName: User._currentUser?.name ?? ''
    };
    UpdateEntityComments({
      partnerId: User._token?.orgs[User._orgIndex].orgId ?? 0,
      entityId: +(this.actionProduct.entityId ?? 0),
      entityType: this.getEntityApiWord(),
      data: data
    }).then(() => {
      this.$root.$emit('alertsUpdated');
    });
    trackEvent(`User actioned ${this.getAppName(false)} alert`, {
      comment: this.note,
      entityId: (this.actionProduct.entityId ?? 0).toString()
    });

    GA.event<EventAlertAddNote>(
      this.$gtag,
      new EventAlertAddNote(
        this.actionProduct.productId,
        this.actionProduct.entityId,
        this.getEntityApiWord()
      )
    );

    this.closeModal();
  }

  public closeModal() {
    this.note = '';
    this.$root.$emit(
      'bv::hide::modal',
      `${this.actionProduct.productId}_${this.actionProduct.entityId}actionAlertModal`,
      '#btnHide'
    );
  }
}
