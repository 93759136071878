
import AppName from '@/components/mixin/AppName.vue';
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import ConstantsMixin from '../mixin/Constants.vue';

@Component
export default class TemperatureIcon extends mixins(AppName, ConstantsMixin) {
  @Prop() item!: Product;
  @Prop() entity!: any;
  @Prop() index!: number;

  get vats() {
    if (this.index == -1) {
      return this.item.entities;
    }
    return [this.entity];
  }
}
